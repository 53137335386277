@import "../../../../assets/scss/_base";
@import "../../../../assets/scss/_mixins";

.b-header{
  padding: em(14) em(42);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  z-index: 100;
  position: relative;
  &_transparent{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
  }
  &__left{
    width: 50%;
  }
  &__right{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &_transparent &__logo{
    position: fixed;
    top: 20px;
    left: 30px;
    z-index: 10;
  }
  &__search{
    margin-right: em(20);
    position: relative;
    z-index: 10;
    form{
      display: flex;
      align-items: center;
    }
  }
  &__search-submit{
    font-size: $base__font-size * 1px;
    border: 0;
    cursor: pointer;
    width: 34px;
    height: 28px;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOSIgaGVpZ2h0PSIyOCIgdmlld0JveD0iMCAwIDI5IDI4Ij48Zz48Zz48cGF0aCBmaWxsPSIjZTcyZDU1IiBkPSJNMTEuMzg5IDE5LjI3NWE3LjY5NyA3LjY5NyAwIDEgMSAwLTE1LjM5MyA3LjY5NyA3LjY5NyAwIDEgMSAwIDE1LjM5M3ptMTYuMTMgNC44MzJsLTYuNDgtNi40OGExMS4zMyAxMS4zMyAwIDAgMCAxLjc0LTYuMDQ4YzAtNi4yOS01LjEtMTEuMzg5LTExLjM5LTExLjM4OUM1LjA5OS4xOSAwIDUuMjg5IDAgMTEuNTc5YzAgNi4yOSA1LjA5OSAxMS4zODkgMTEuMzg5IDExLjM4OSAyLjQyIDAgNC42Ni0uNzU3IDYuNTA1LTIuMDQybDYuNDAzIDYuNDAzYTIuMjcyIDIuMjcyIDAgMCAwIDEuNjEuNjY2IDIuMjc4IDIuMjc4IDAgMCAwIDEuNjEtMy44ODd6Ii8+PC9nPjwvZz48L3N2Zz4=) no-repeat 50% 50% transparent;
    display: block;
    z-index: 10;
    box-sizing: border-box;
  }
  &_transparent &__search{
    position: fixed;
    top: 30px;
    right: 104px;
    box-shadow: 0 30px 40px rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    border-radius: 10px;
    transition: box-shadow .3s;
    &-submit{
      padding: 15px;
      width: 64px;
      height: 58px;
    }
  }
  &__search-input-wrap{
    width: 0;
    padding: 0;
    transition: width .3s, padding .3s;
    box-sizing: border-box;
    overflow: hidden;
  }
  &__search.opened &__search-input-wrap{
    width: 240px;
    padding: 0 em(19);
  }
  &__search-input{
    $font-size: 18;
    font-size: em($font-size);
    width: 100%;
    height: 30px;
    color: #333333;
    font-weight: 500;
    border: 0;
    border-bottom: 1px solid #dadada;
    transition: border-color .3s;
    display: block;
    box-sizing: border-box;
    &:focus{
      border-bottom-color: #e72d55;
    }
  }
}

@media (max-width: ($base__size_md - 1) * 1px) {
  .b-header{
    &_transparent &__logo, &__logo{
      width: 230px;
      img{
        width: 100%;
      }
    }
  }
}

@media (max-width: ($base__size_sm - 1) * 1px) {
  .b-header{
    padding: em(6) em(15);
    &_transparent &__logo, &__logo{
      width: 150px;
      top: 15px;
      left: 15px;
    }
    &__search{
      background-color: #ffffff;
      border-radius: 10px;
      transition: box-shadow .3s;
      &.opened{
        box-shadow: 0 30px 40px rgba(0, 0, 0, 0.3);
      }
    }
    &__search-submit{
      width: 40px;
      height: 40px;
      background-size: 25px auto;
    }
    &__search.opened &__search-input-wrap{
      width: 180px;
      padding: 0 em(10);
    }
    &_transparent &__search{
      top: 15px;
      right: 50px;
      box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
      &-submit{
        padding: 10px;
        width: 40px;
        height: 40px;
      }
    }
  }
}