@import url('https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c:400,500,700&display=swap&subset=cyrillic');

@import "assets/scss/_base";
@import "assets/scss/_reset";
@import "assets/scss/_mixins";
@import "assets/scss/_grid";
@import "assets/scss/_fonts";

@import "assets/scss/_b-text";
@import "assets/scss/_b-form";

.menu-opened{
  overflow: hidden;
}
html{
  height: 100%;
}
body{
  height: 100%;
  &.grid{
    height: auto;
  }
}


.grid-container {
  user-select: none;
  width: 100vw;
  height: 100vh;
  position: absolute;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grid-container:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

