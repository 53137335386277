@import "../../../../assets/scss/_base";
@import "../../../../assets/scss/_mixins";

.b-page{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__title{
    color: #e52937;
    $font-size: 50;
    font-size: em($font-size);
    font-weight: 500;
    margin-bottom: em(46, $font-size);
  }
  &__icon{
    max-width: 100%;
  }
  &__float-dev-logo{
    box-shadow: 0 30px 40px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #ffffff;
    padding: em(18);
    width: em(104);
    position: fixed;
    bottom: em(40);
    left: em(40);
    z-index: 100;
    display: block;
    cursor: pointer;
    img{
      width: 100%;
    }
  }
}

@media (max-width: ($base__size_md - 1) * 1px) {
  .b-page{
    &__float-dev-logo{
      box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      padding: em(10);
      width: em(70);
      bottom: em(25);
      left: em(25);
      img{
        width: 100%;
      }
    }
  }
}

@media (max-width: ($base__size_sm - 1) * 1px) {
  .b-page{
    &__float-dev-logo{
      width: em(50);
      bottom: em(15);
      left: em(15);
    }
    &__title{
      $font-size: 40;
      font-size: em($font-size);
      margin-bottom: em(30, $font-size);
    }
  }
}