html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
html{
    font-size: 10px;
}
body {
    line-height: 1.5;
    font-size:$base__font-size*1px;
    color:$base__color;
    font-family: $base__font;
    background:$base__background;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
a{
    color: #000;
}
a:focus,input:focus,button:focus{
    outline:none;
}
a:hover{
    text-decoration: none;
}
strong, b{
    font-weight: 700;
}
sup {
    vertical-align: top;
    font-size: 0.6em;
}
em{
    font-style: italic;
}