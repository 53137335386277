@import "../../../../assets/scss/_base";
@import "../../../../assets/scss/_mixins";

.b-menu{
  position: relative;
  z-index: 30;
  &__list{
    position: absolute;
    top: em(-10);
    right: 0;
    padding: em(40);
    list-style: none;
    display: none;
    background-color: #fff;
    box-shadow: 0 30px 40px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    li{
      padding: em(3) 0;
    }
    a{
      display: block;
      color: #e72d55;
      $font-size: 16;
      font-size: em($font-size);
      font-weight: 500;
      white-space: nowrap;
      text-decoration: none;
    }
  }
  &__button{
    position: relative;
    transform: rotate(0);
    transition: .5s ease-in-out;
    cursor: pointer;
    width: 34px;
    border: 0;
    height: 28px;
    background-color: transparent;
    display: block;
    z-index: 10;
    span{
      display: block;
      position: absolute;
      height: 6px;
      width: 100%;
      background: #e72d55;
      border-radius: 3px;
      opacity: 1;
      left: 0;
      transform: rotate(0);
      transition: .25s ease-in-out, background-color .3s;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 11px;
      }
      &:nth-child(3) {
        top: 22px;
      }
    }
    &:hover{
      span{
        background-color: #848D9C;
      }
    }
  }
  &.open &__button{
    span{
      background-color: #dadada;
      height: 2px;
      width: 14px;
      &:nth-child(1) {
        top: 18px;
        transform: rotate(135deg);
      }
      &:nth-child(2) {
        opacity: 0;
        left: -60px;
      }
      &:nth-child(3) {
        top: 18px;
        transform: rotate(-135deg);
      }
    }
  }

  &.open &__list{
    display: block;
  }
  &__separator{
    width: 100%;
    height: 1px;
    padding: 0!important;
    margin: 10px 0;
    background-color: #e72d55;
  }
}

.b-header_transparent{
  .b-menu{
    position: fixed;
    top: 30px;
    right: 40px;
    box-shadow: 0 30px 40px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #ffffff;
    padding: 15px;
  }
}

@media (max-width: ($base__size_sm - 1) * 1px) {
  .b-menu{
    &__button{
      width: 20px;
      height: 20px;
      span{
        height: 4px;
        width: 100%;
        border-radius: 2px;
        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          top: 8px;
        }
        &:nth-child(3) {
          top: 16px;
        }
      }
    }
    &.open &__button{
      span{
        background-color: #dadada;
        height: 2px;
        width: 14px;
        &:nth-child(1) {
          top: 18px;
          transform: rotate(135deg);
        }
        &:nth-child(2) {
          opacity: 0;
          left: -60px;
        }
        &:nth-child(3) {
          top: 18px;
          transform: rotate(-135deg);
        }
      }
    }
  }
  .b-header_transparent{
    .b-menu{
      top: 15px;
      right: 15px;
      box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      padding: 10px;
    }
  }
}