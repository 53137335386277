@import "_base";
@import "_mixins";
.b-category{
  &__header{
    padding: 0 0 em(89) 0;
  }
  &__name{
    color: #e52937;
    $font-size: 24;
    font-size: em($font-size);
    font-weight: 500;
    padding: 0 em(50, $font-size);
    border-radius: em(20, $font-size);
    border: 1px solid #e52937;
    display: inline-block;
  }
  &__icons{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__search-field{
    $font-size: 24;
    font-size: em($font-size);
    width: 100%;
    max-width: em(360, $font-size);
    box-sizing: border-box;
    height: em(38, $font-size);
    color: #e52937;
    font-family: $base__font;
    font-weight: 500;
    padding: 0 em(15, $font-size);
    border: 0;
    border-bottom: 1px solid #dadada;
    display: block;
    transition: border-color .3s;
    &:focus{
      border-bottom-color: #e52937;
    }
  }
  &__empty-text{
    color: #e52937;
    $font-size: 35;
    font-size: em($font-size);
    font-weight: 500;
  }
}

@media (max-width: ($base__size_sm - 1) * 1px) {
  .b-category{
    &__header{
      padding: 0 0 em(30) 0;
    }
    &__name{
      $font-size: 18;
      font-size: em($font-size);
      padding: em(5, $font-size) em(30, $font-size);
      border-radius: em(20, $font-size);
    }
  }
}