@import "../../../../assets/scss/_base";
@import "../../../../assets/scss/_mixins";

.b-main{
  padding: em(96) 0;
}

@media (max-width: ($base__size_sm - 1) * 1px) {
  .b-main{
    padding: em(30) 0;
  }
}