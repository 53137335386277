@import "../../../../assets/scss/_base";
@import "../../../../assets/scss/_mixins";

.b-icon{
  transition: opacity 0.5s;
  opacity: 1;
  //overflow: hidden;
  position: absolute;
  width: 145px;
  height: 145px;
  padding: 12px;
  display: block;
  cursor: pointer;
  margin-bottom: 8px;
  &.hidden {
    opacity: 0;
  }
  &:after{
    content:"";
    display: block;
    width: calc(100% - 24px);
    height: 85px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #ffffff;
    position: absolute;
    bottom: 0;
    left: 12px;
  }
  &_catalog{
    position: relative;
  }
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
  &__title{
    color: #333333;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    position: absolute;
    z-index: 10;
    width: calc(100% - 24px);
    padding: 0 10px;
    bottom: 9px;
  }
  &__overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    cursor: pointer;
  }
  &__wrap{
    padding: 0 11px;
    height: 78px;
    margin-bottom: 7px;
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}