.b-form{
  &__el{
    margin-bottom:em(25);
    &_submit{
      text-align: right;
    }
  }
  &__text, &__textarea{
    color: #2b2b2b;
    $font-size: 14;
    font-size: em($font-size);
    display: block;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #dadada;
    font-family: $base__font;
    height: em(30, $font-size);
    transition: border-color .3s;
    &:focus{
      outline: none;
      border-bottom-color: #2b2b2b;
    }
  }
  &__textarea{
    resize: none;
    height: auto;
  }
  &__submit{
    color: #ffffff;
    $font-size: 12;
    font-size: em($font-size);
    font-weight: 700;
    border-radius: 5px;
    background-color: #e52937;
    border: 0;
    padding: em(8, $font-size) em(60, $font-size);
    cursor: pointer;
    transition: background-color .3s;
    &:hover{
      background-color: #848D9C;
    }
  }
}