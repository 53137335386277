.b-text{
  p{
    color: #2b2b2b;
    $font-size: 16;
    font-size: em($font-size);
    line-height: 1.5;
    margin-bottom: em(25, $font-size);
  }
}

@media (max-width: ($base__size_sm - 1) * 1px) {
  .b-text{
    p{
      $font-size: 14;
      font-size: em($font-size);
      margin-bottom: em(15, $font-size);
    }
  }
}